import React, { useEffect, useState } from 'react';
import styles from './myStyles.module.css';
import Typewriter from 'typewriter-effect';
import { Box, FormControl, InputLabel, NativeSelect, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserImage from './user.png'
import NoPagesFound from '../NoPagesFound'
import LoadingPages from '../LoadingPages'
import axios from 'axios';
import {BASE_URL} from "../../constant"
const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogsTitle, setBlogsTitle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [blogsTitleValue, setBlogsTitleValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/carrer-form/get-all-blog`, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return;
      }
      setBlogs(data?.blogs);
      setFilteredBlogs(data?.blogs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const fetchDataMenu = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/position/get-all-blog/all-title`, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return;
      }
      setBlogsTitle(data?.blogs);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataMenu();
  }, []);

  const handleRemove = async (userId) => {
    try {
      const { data } = await axios.delete(`${BASE_URL}/carrer-form/delete-blog/${userId}`, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return;
      }
      await fetchData();
    } catch (error) {
      console.log({ error });
    }
  };

  const applyFilter = () => {
    let filteredUsers = blogs.filter((item) => {
      const matchesSearchQuery =
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesBlogTitle =
        blogsTitleValue === "" || item.position === blogsTitleValue;
      return matchesSearchQuery && matchesBlogTitle;
    });

    filteredUsers = filteredUsers.sort((a, b) => {
      if (sortOrder === "latest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });

    setFilteredBlogs(filteredUsers);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      applyFilter();
    }, 500);
    return () => clearTimeout(time);
  }, [searchQuery, sortOrder, blogs, blogsTitleValue]);

  return (
    <div>
      <div style={{ margin: "30px 0px", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "30%" }}>
          <TextField
            id="outlined-basic"
            label="Search by name or email"
            variant="outlined"
            style={{ width: "100%" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", width: "40%", marginLeft: "12px" }}>
          <FormControl fullWidth style={{ width: "80%", margin: "16px 8px" }}>
            <NativeSelect
              value={blogsTitleValue}
              onChange={(e) => setBlogsTitleValue(e.target.value)}
            >
              <option value="" style={{ textAlign: "center" }}>Select a blog title</option>
              {blogsTitle.map((blog) => (
                <option key={blog._id} value={blog.title} style={{ textAlign: "center" }}>
                  {blog.title}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth style={{ margin: "0px 8px", width: "20%" }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Sort
            </InputLabel>
            <NativeSelect
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value={"latest"} style={{ textAlign: "center" }}>Latest</option>
              <option value={"earliest"} style={{ textAlign: "center" }}>Earliest</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      {loading ? (
        <LoadingPages />
      ) : (
        <>
          {filteredBlogs?.length === 0 ? (
            <NoPagesFound />
          ) : (
            <div style={{ overflowX: "auto",scrollbarWidth:"thin"}}> 
              <table style={{ width: "100%", borderCollapse: "collapse", minWidth: "1000px",padding:'8px' }}>
                <thead>
                  <tr>
                    <th className={`${styles.column} ${styles.headingTable}`}>Position</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Name</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Email</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Phone</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>College</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Year</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Department</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Intern Duration</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Date</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Resume Link</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Referral Email</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Where Hear Job</th>
                    <th className={`${styles.column} ${styles.headingTable}`}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBlogs.map((post) => (
                    <tr key={post?._id}>
                      <td className={styles.column}>{post.position}</td>
                      <td className={styles.column} >{post.name}</td>
                      <td className={styles.column}>{post.email}</td>
                      <td className={styles.column}>{post.phone}</td>
                      <td className={styles.column}>{post.college}</td>
                      <td className={styles.column}>{post.year}</td>
                      <td className={styles.column}>{post.department}</td>
                      <td className={styles.column}>{post.intern_duration}</td>
                      <td className={styles.column}>{post.date}</td>
                      <td className={styles.column}>{post.resume_link}</td>
                      <td className={styles.column}>{post.referal_email_id}</td>
                      <td className={styles.column}>{post.where_hear_job}</td>
                      <td className={styles.column}>
                        <button
                          className={styles.btnLink}
                          onClick={() => handleRemove(post._id)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Blog;

