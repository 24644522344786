import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function Index() {
    const { token } = useParams();
    const navigate=useNavigate()
    if(token){
        localStorage.setItem('accessToken',JSON.stringify(token))
    }
    window.location.href = '/';
}
