import React from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Blog = () => {
    const hackathons = [
        {
            title: "Innovate Together Hackathon",
            description: "Join us for a weekend of innovation and collaboration! Work in teams to develop solutions to real-world challenges. Open for remote participants from colleges across India.",
            date: "November 10-12, 2024",
            location: "Virtual",
            prize: "Upto INR 30,000 in cash prizes and mentorship opportunities",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Code for Good Hackathon",
            description: "Use your coding skills for a noble cause! Create projects that make a positive impact in your community. Remote participation available for colleges all over India.",
            date: "December 1-3, 2024",
            location: "Community Center, New Delhi & Virtual",
            prize: "Upto INR 30,000 for best social impact projects",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Tech for Tomorrow Hackathon",
            description: "Collaborate with like-minded innovators and engineers to develop cutting-edge solutions for future tech challenges. Remote participants are welcome from colleges across India.",
            date: "January 15-17, 2025",
            location: "IIT Bombay Campus & Virtual",
            prize: "Upto INR 30,000 in prizes and internship opportunities",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "AI & ML Challenge",
            description: "Dive into AI and machine learning! Develop AI-driven solutions for real-world applications. Open for remote participation from students in colleges across India.",
            date: "February 5-7, 2025",
            location: "Virtual",
            prize: "Upto INR 30,000 in scholarships and mentorship",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Blockchain Bootcamp Hackathon",
            description: "Join us for the Blockchain Bootcamp Hackathon and learn to create decentralized applications. Remote participation is open to all colleges in India.",
            date: "March 10-12, 2025",
            location: "NIT Surat Campus & Virtual",
            prize: "Upto INR 30,000 for best blockchain projects",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Women in Tech Hackathon",
            description: "A dedicated hackathon for women to showcase their skills and collaborate on tech projects. Empowering women in technology! Remote participation available for women from colleges across India.",
            date: "April 20-22, 2025",
            location: "Online & Offline at Various Locations",
            prize: "Upto INR 30,000 in networking opportunities and mentorship",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Smart City Hackathon",
            description: "Join forces to design innovative solutions for smart cities. Address real urban challenges! Remote participation welcomed from students in colleges throughout India.",
            date: "May 15-17, 2025",
            location: "Bangalore Tech Park & Virtual",
            prize: "Upto INR 30,000 for projects that can be piloted in real-world environments",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Gaming Hackathon",
            description: "Are you a gamer or game developer? Join the Gaming Hackathon to create exciting new games and prototypes! Open for remote participants from colleges all over India.",
            date: "June 25-27, 2025",
            location: "Hyderabad Tech Hub & Virtual",
            prize: "Upto INR 30,000 in gaming consoles and software licenses",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Fintech Innovation Hackathon",
            description: "Explore the future of finance! Develop new financial solutions and technologies. Remote participation is available for students from colleges across India.",
            date: "July 10-12, 2025",
            location: "Mumbai Financial District & Virtual",
            prize: "Upto INR 30,000 in cash prizes and potential investment opportunities",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "HealthTech Hackathon",
            description: "Focus on creating technology solutions that improve health and wellness. Collaborate with healthcare professionals and innovators. Remote participation open for colleges all over India.",
            date: "August 18-20, 2025",
            location: "Chennai Medical Research Institute & Virtual",
            prize: "Upto INR 30,000 in mentorship from health industry experts",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        },
        {
            title: "Eco-Hackathon",
            description: "Tackle environmental issues through technology! Join the Eco-Hackathon to develop sustainable solutions for a greener planet. Remote participation is available for participants from colleges across India.",
            date: "September 12-14, 2025",
            location: "Pune Eco Park & Virtual",
            prize: "Upto INR 30,000 for funding sustainable projects",
            imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
        }
    ];
    const navigate = useNavigate();
  return (
    <div>
        <Box className={styles.textLoop}>
       UpCommings
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Hackathons..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </Box>  

      <div  className={styles.container}>
        {hackathons.map((post, index) => (
          <div  className={styles.blogPost} key={index}>
            <img src="https://www.atulhost.com/wp-content/uploads/2021/02/hackathon.png" alt={post.title} />
            <h2 style={{fontWeight:800,fontSize:'14px'}}>{post.title}</h2>
            <h5><span>Date: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.date}</span> </h5>
            <h5><span>Location: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.location} </span></h5>
            <h5><span>Prize: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.prize}</span> </h5>
            <p style={{fontWeight:600,fontSize:'10px'}}>{post.description}</p>
            <div style={{display:'flex',justifyContent:'center'}}>

            <a  className={styles.btnLink} onClick={() => navigate(`/hackathonform/${post.title}`)} target="_blank" style={{width:"115px",height:'40px'}}>Register</a>
     </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;