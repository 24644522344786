import React, { useEffect, useState } from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box, FormControl, InputLabel, NativeSelect, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserImage from './user.png'
import axios from 'axios';
import {BASE_URL} from "../../constant"
const Blog = () => {
    const [blogs,setBlogs]=useState([])
    const [searchQuery, setSearchQuery] = useState('');
  const [userRole, setUserRole] = useState('all'); 
  const [sortOrder, setSortOrder] = useState('latest');
  const [filteredBlogs,setFilteredBlogs]=useState([])   
    const fetchData=async()=>{
        try {
            const {data} = await axios.get(`${BASE_URL}/auth/get-all-user`,{
          withCredentials:true
        });
              console.log(data?.blogs)
              if(!(data?.success)){
                return
              }
              setBlogs(data?.blogs)
              setFilteredBlogs(data?.blogs)
        } catch (error) {
            console.log({error})
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
    const handleMakeAdmin=async(userId)=>{
      try {
        const {data}=await axios.get(`${BASE_URL}/admin/grant-admin/${userId}`,{
          withCredentials:true
        })
        if(!(data?.success)){
          return
        }
        fetchData()
      } catch (error) {
        
      }
    }
    const handleRemoveAdmin=async(userId)=>{
      try {
        const {data}=await axios.get(`${BASE_URL}/admin/remove-admin/${userId}`,{
          withCredentials:true
        })
        if(!(data?.success)){
          return
        }
        fetchData()
      } catch (error) {
        
      }
    }
    const applyFilter = () => {
      let filteredUsers = blogs.filter((item) => {
        const matchesSearchQuery =
          item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesUserRole =
          userRole === 'all' ||
          (userRole === 'admin' && item?.role === 'admin') ||
          (userRole === 'user' && item?.role === 'user');
  
        return matchesSearchQuery && matchesUserRole;
      });
      filteredUsers = filteredUsers.sort((a, b) => {
        if (sortOrder === 'latest') {
          console.log(new Date(b.createdAt))
          return new Date(b.createdAt) - new Date(a.createdAt); 
        } else {
          return new Date(a.createdAt) - new Date(b.createdAt); 
        }
      });
  
      setFilteredBlogs(filteredUsers);
    };
    useEffect(() => {
      const time = setTimeout(() => {
        applyFilter();
      }, 500); 
      return () => clearTimeout(time);
    }, [searchQuery, userRole, sortOrder, blogs]);
    
  return (
    <div>
      <div style={{margin:'30px 0px',display:'flex',justifyContent:'center'}}>
        <div  style={{width:'40%'}}>
        <TextField
            id="outlined-basic"
            label="Search by name or email"
            variant="outlined"
            style={{ width: '100%' }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '20%', marginLeft: '12px' }}>
          <FormControl fullWidth style={{ margin: '0px 8px' }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              User
            </InputLabel>
            <NativeSelect
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              <option value={"all"} style={{textAlign:'center'}}>All</option>
              <option value={"admin"} style={{textAlign:'center'}}>Admin</option>
              <option value={"user"} style={{textAlign:'center'}}>User</option>
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth style={{ margin: '0px 8px' }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Sort
            </InputLabel>
            <NativeSelect
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)} 
            >
              <option value={"latest"} style={{textAlign:'center'}}>Latest</option>
              <option value={"earliest"} style={{textAlign:'center'}}>Earliest</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <div  className={styles.container}>
        {filteredBlogs.map((post, index) => (<>
          <div  className={styles.blogPost} key={post?._id}>
            <div style={{display:'flex',justifyContent:'center'}}>
            <img src={UserImage} alt={post.title} style={{height:'200px',width:'200px'}} />
            </div>
            <h5><span>name: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.name}</span> </h5>
            <h5><span>email: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.email} </span></h5>
            <h5><span>role: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.role} </span></h5>            
           
            <div style={{display:'flex',justifyContent:'center'}}>
            <a  className={styles.btnLink} onClick={() => handleMakeAdmin(post._id)} target="_blank" style={{width:"115px",height:'40px'}}>Admin</a>
            <a  className={styles.btnLink} onClick={() => handleRemoveAdmin(post._id)} target="_blank" style={{width:"115px",height:'40px',marginLeft:'8px'}}>User</a>
            
     </div>
          </div>
          </>))}
      </div>
    </div>
  );
};

export default Blog;