import React, { useEffect, useState } from 'react'
import styles from './style.module.css';
import TraningComponent from "../../components/TraningComponent"
import CourseComponent from "../../components/CourseComponent"
import CarrerPaymentComponent from "../../components/CarrerPaymentComponent"
import ApplyFormComponentComponent from "../../components/ApplyFormComponent"
import HackathonComponent from "../../components/HackathonComponent"
import UserComponent from "../../components/UserComponent"
import { jwtDecode } from "jwt-decode";


export default function Index() {
    const [selectedState,setSelectedState]=useState("traning")
    useEffect(()=>{
      const accessToken=JSON.parse(localStorage.getItem("accessToken"))
      if(accessToken){
        const decoded = jwtDecode(accessToken);
            if(decoded?.role!=="admin"){
              window.location.href = '/';
            }
      }else{
        window.location.href = '/';
      }
    },[])

  return (
    <div style={{margin:'22px'}}>
      <div className={styles.container} >
        <div className={styles.heading} style={{color:selectedState==="traning"&& 'red'}} onClick={()=>setSelectedState("traning")}>Traning</div>
        <div className={styles.heading} style={{color:selectedState==="carrer"&& 'red'}} onClick={()=>setSelectedState("carrer")}>Carrer</div>
        <div className={styles.heading} style={{color:selectedState==="carrerPayment"&& 'red'}} onClick={()=>setSelectedState("carrerPayment")}>CarrerPayment</div>
        <div className={styles.heading} style={{color:selectedState==="course"&& 'red'}} onClick={()=>setSelectedState("course")}>Course</div>
        <div className={styles.heading} style={{color:selectedState==="hackathon"&& 'red'}} onClick={()=>setSelectedState("hackathon")}>Hackathon</div>
        <div className={styles.heading} style={{color:selectedState==="user"&& 'red'}} onClick={()=>setSelectedState("user")}>User</div>
      </div>
      {
        selectedState==="traning" && <TraningComponent/>
      }
      {
        selectedState==="carrer" && <ApplyFormComponentComponent/>
      }
      {
        selectedState==="carrerPayment" && <CarrerPaymentComponent/>
      }
      {
        selectedState==="course" && <CourseComponent/>
      }
      {
        selectedState==="hackathon" && <HackathonComponent/>
      }
      {
        selectedState==="user" && <UserComponent/>
      }
    </div>
  )
}
