import React, { useContext, useEffect, useState } from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContex } from '../../contex/AuthContex';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import NoPagesFound from '../../components/NoPagesFound';
import LoadingPages from "../../components/LoadingPages"
import {BASE_URL} from "../../constant"

 function BlogComponent({post}) {   
    const navigate = useNavigate();
  return (
    <div  className={styles.blogPost} key={post?._id}>
            <div>
            <img src={`${BASE_URL}/Images/${post.imgSrc}`} alt={post?.title} />
            </div>
            <h2 style={{fontWeight:800,fontSize:'14px'}}>
                <>{post.title}</> 
            </h2>
            <h5><span>Instructor: </span>{post.Instructor}
            </h5>
            <p style={{fontWeight:800,fontSize:'12px'}}>{post.Background}
            </p>
            <p style={{fontWeight:600,fontSize:'10px'}}>{post.description}
            </p>
            <div style={{display:'flex',justifyContent:'center'}}> 
                <a  className={styles.btnLink} onClick={() => navigate(`/playlist/video/${post.title}/${post._id}`)} target="_blank" style={{width:"115px",height:'40px',marginLeft:'8px'}}>Start</a>
     </div> 
          </div>
  )
}

const Blog = () => {
  const { isAdmin,setIsAdmin,isLogin,setIsLogin } = useContext(AuthContex)
  const [blogs,setBlogs]=useState([])
  const [loading, setLoading] = useState(false)
    const blogPosts = [
        {
            title: "Full Stack Web Development",
            Instructor: "Arjun Verma",
            Background: "IIT Delhi, Senior Web Developer at Amazon",
            description: "Learn to build modern web applications from scratch. This course covers both front-end (HTML, CSS, JavaScript, React) and back-end (Node.js, Express, MongoDB) development, ensuring you master the complete web development process.",
            imgSrc: "https://emaillistvalidation.com/blog/content/images/2023/10/Mern.png"
        },
        {
            title: "Java Full Stack Development",
            Instructor: "Sneha Kapoor",
            Background: "NIT Trichy, Senior Java Developer at Flipkart",
            description: "Master Java's back-end development along with front-end frameworks like React and Angular. Gain expertise in Java Spring Boot, Hibernate, REST APIs, and MySQL to build robust full-stack applications.",
            imgSrc: "https://th.bing.com/th/id/OIP.Qwta26SNFoftkL231g4APAHaEc?w=1000&h=600&rs=1&pid=ImgDetMain"
        },
        {
            title: "Mobile App Development (Android)",
            Instructor: "Rohan Desai",
            Background: "IIT Bombay, Android Engineer at Google",
            description: "Get hands-on experience in building Android applications using Java and Kotlin. Learn how to create, test, and deploy scalable mobile apps to the Google Play Store.",
            imgSrc: "https://staticlearn.shine.com/l/m/images/blog/Android.jpg"
       },
        {
            title: "Data Science and Analytics",
            Instructor: "Neha Sharma",
            Background: "IIIT Hyderabad, Data Scientist at Microsoft",
            description: "Dive deep into data analysis and visualization techniques using Python, R, and popular libraries like Pandas, NumPy, and Matplotlib. This course also covers machine learning models and statistical analysis to help you become proficient in data-driven decision-making.",
            imgSrc: "https://th.bing.com/th/id/OIP.sdhSluzG5ba8G0angIGs5AHaEK?rs=1&pid=ImgDetMain"
        },
        {
            title: "Artificial Intelligence & Machine Learning",
            Instructor: "Vikram Patel",
            Background: "IIT Kanpur, AI Researcher at Facebook",
            description: "Learn how to build AI models and integrate them into real-world applications. Topics include supervised and unsupervised learning, neural networks, natural language processing, and TensorFlow for machine learning projects.",
            imgSrc: "https://1.bp.blogspot.com/-GhlZqYUD-_I/X3HJ2oS3WCI/AAAAAAAAAEA/zsjT20-Obh8IqXb_DUBgeYFXWdJwDgWiwCNcBGAsYHQ/w1200-h630-p-k-no-nu/Ai-ml-dl.jpg"
        },
        {
            title: "Cybersecurity",
            Instructor: "Aman Gupta",
            Background: "IIT Kharagpur, Security Engineer at Cisco",
            description: "Understand the foundations of cybersecurity, ethical hacking, and penetration testing. Learn how to secure web applications, identify vulnerabilities, and protect systems from cyber threats using tools like Kali Linux and Metasploit.",
            imgSrc: "https://i1.wp.com/securenetworkers.com/wp-content/uploads/2019/12/AdobeStock_199796751-scaled.jpeg?fit=2560%2C1491&ssl=1"
        },
        {
            title: "Cloud Computing (AWS/Azure/GCP)",
            Instructor: "Priya Sinha",
            Background: "NIT Calicut, Cloud Architect at IBM",
            description: "Gain comprehensive knowledge of cloud infrastructure, cloud services, and cloud architecture. Master the essentials of AWS, Azure, or Google Cloud, including deployment, management, and security of cloud-based applications.",
            imgSrc: "https://icsblog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2023/04/29163136/4-Pillars-of-Cloud-Computing-1.jpg"
        },
        {
            title: "DevOps",
            Instructor: "Karan Thakur",
            Background: "IIT Roorkee, DevOps Engineer at Red Hat",
            description: "Become proficient in automating software deployment, monitoring, and scaling applications. Learn CI/CD pipelines, version control with Git, Docker, Kubernetes, Jenkins, and infrastructure automation with Terraform.",
            imgSrc: "https://img.freepik.com/free-vector/gradient-devops-illustration_23-2149370941.jpg"
        },
        {
            title: "Blockchain Development",
            Instructor: "Siddharth Jain",
            Background: "IIT Madras, Blockchain Developer at Wipro",
            description: "Explore blockchain technology, including smart contracts, Ethereum, and decentralized apps (dApps). Learn to build blockchain-based solutions and understand how cryptocurrencies and blockchain architecture work.",
            imgSrc: "https://www.portnox.com/wp-content/uploads/2023/06/Blockchain-and-IoT-1.png"
        },
        {
            title: "Software Testing & QA",
            Instructor: "Tanvi Mehta",
            Background: "IIT Guwahati, QA Lead at TCS",
            description: "Learn manual and automated testing strategies for software applications. This course includes unit testing, functional testing, Selenium automation, and CI/CD integration for ensuring software quality.",
            imgSrc: "https://th.bing.com/th/id/OIP.sRJEaoyGEmlQ0H4ovwV-MgAAAA?rs=1&pid=ImgDetMain"
        },
        {
            title: "UI/UX Design",
            Instructor: "Shreya Ghosh",
            Background: "IIT Delhi, Lead UX Designer at Adobe",
            description: "Master the art of creating intuitive and visually appealing interfaces for web and mobile applications. This course covers design principles, prototyping, wireframing, and tools like Figma, Sketch, and Adobe XD.",
            imgSrc: "https://wallpapercave.com/wp/wp9775513.jpg"
        },
        {
            title: "Python Programming",
            Instructor: "Aniket Roy",
            Background: "IIIT Bangalore, Software Developer at Infosys",
            description: "Start from the basics and move to advanced Python concepts like OOP, web scraping, data manipulation, and working with APIs. This course is perfect for beginners and those looking to build a career in software development or data science.",
            imgSrc: "https://images.datacamp.com/image/upload/v1646560927/shutterstock_1329434768_bf9003ea47.jpg"
        },
        {
            title: "Internet of Things (IoT) Development",
            Instructor: "Rajeev Malhotra",
            Background: "IIT Bombay, IoT Engineer at Siemens",
            description: "Learn to build IoT applications and devices by combining hardware and software knowledge. This course covers IoT architecture, sensor integration, data transmission, and analytics using platforms like Arduino and Raspberry Pi.",
            imgSrc: "https://images.squarespace-cdn.com/content/v1/55181a36e4b05c72e7f6a2a3/1644642964684-IFR844O0H5ODZMLIHES2/2-1-1.png"
        },
        {
            title: "Cloud Native Application Development",
            Instructor: "Meghna Deshmukh",
            Background: "IIT Hyderabad, Senior Cloud Developer at Microsoft",
            description: "This course focuses on designing, developing, and deploying applications that fully utilize cloud infrastructure. Learn microservices architecture, containerization, and scaling using tools like Docker and Kubernetes.",
            imgSrc: "https://th.bing.com/th/id/OIP.yGvAAV7oGACnJcTv848mVQAAAA?rs=1&pid=ImgDetMain"
        },
        {
            title: "Database Management & SQL",
            Instructor: "Rakesh Kumar",
            Background: "NIT Warangal, Database Architect at Oracle",
            description: "Learn how to manage, design, and query databases using SQL. This course covers relational database concepts, database optimization, and working with MySQL, PostgreSQL, and NoSQL databases.",
            imgSrc: "https://www.simplilearn.com/ice9/free_resources_article_thumb/Concat_in_SQL.jpg"
        },
        {
            title: "Ethical Hacking",
            Instructor: "Anurag Sinha",
            Background: "IIT Delhi, Ethical Hacker at Zscaler",
            description: "Learn penetration testing, vulnerability analysis, and network security. This hands-on course teaches you how to think like a hacker while helping organizations secure their systems from cyber threats.",
            imgSrc: "https://wallpapercave.com/wp/wp9358224.jpg"
        },
        {
            title: "Angular Development",
            Instructor: "Sumit Reddy",
            Background: "IIIT Delhi, Angular Developer at Swiggy",
            description: "Master Angular, one of the most popular JavaScript frameworks for building web applications. Learn to create single-page applications, manage state, and work with RESTful APIs.",
            imgSrc: "https://binmile.com/wp-content/uploads/2021/04/why-businesses-elect-angular-for-developing-digital-products.jpg"
        },
        {
            title: "React Native Development",
            Instructor: "Nikhil Gupta",
            Background: "IIT Roorkee, Senior Mobile Developer at Paytm",
            description: "Develop cross-platform mobile apps using React Native. This course covers everything from setting up the environment to building and deploying apps for iOS and Android.",
            imgSrc: "https://e0.pxfuel.com/wallpapers/737/10/desktop-wallpaper-react-native.jpg"
        },
        {
            title: "Digital Marketing with SEO",
            Instructor: "Pooja Sharma",
            Background: "IIT Kharagpur, SEO Specialist at Zoho",
            description: "Learn the essentials of digital marketing, including SEO, content marketing, social media marketing, and paid advertising. Get hands-on experience with tools like Google Analytics and SEMrush.",
            imgSrc: "https://c1.wallpaperflare.com/preview/24/329/110/seo-optimization-search-engine-optimization-process.jpg"
        },
        {
            title: "Big Data & Hadoop",
            Instructor: "Aditya Singh",
            Background: "IIT Gandhinagar, Big Data Engineer at LinkedIn",
            description: "Understand big data architecture and processing using Hadoop and Spark. Learn to store, process, and analyze large datasets to gain actionable insights using distributed computing frameworks.",
            imgSrc: "https://development.analyticsinsight.net/wp-content/uploads/2021/09/10-Ways-Big-Data-Has-Changed-Healthcare-in-India.jpg............................................"
        }
    ];
    useEffect(()=>{
      const accessToken=JSON.parse(localStorage.getItem("accessToken"))
      if(accessToken){
        const decoded = jwtDecode(accessToken);
            if(decoded?.role!=="admin"  && decoded?.role!=="user"){
              window.location.href = '/';
            }
      }else{
        window.location.href = '/';
      }
    },[])
    const fetchData=async()=>{
        try {
            setLoading(true)
            const {data} = await axios.get(`${BASE_URL}/course/get-all-blog/my-course`,{
                withCredentials:true
              });
              console.log(data?.blogs)
              if(!(data?.success)){
                return
              }
              setBlogs(data?.blogs)
              setLoading(false)
        } catch (error) {
            console.log({error})
        }
    }
    useEffect(()=>{
        fetchData()
    },[]) 

  return (
    <div>
        <Box className={styles.textLoop}>
        Yours
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Course..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </Box> 
        {
            loading ?<LoadingPages />:
        
      <div  className={styles.container}>
        {
            blogs.length>0?<>
            {blogs.map((post, index) => (
          <BlogComponent post={post} />
        ))}
            </>:<NoPagesFound contain={"Don't have any course"}></NoPagesFound>
        }
        
      </div>}
    </div>
  );
};

export default Blog;
