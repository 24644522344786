import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import HeroSection from "./components/VideoSection";
import Footer from "./components/Footer";
import styled from "styled-components";
import WhatWeDo from "./components/Courses/index.js"
import Career from './Pages/Career'
import ApplyForm from './Pages/ApplyForm'
import TraningForm from './Pages/TraningForm'
import HackathonForm from './Pages/HackathonForm'
import Hackathon from "./Pages/Hackathon";
import Tranings from "./Pages/Tranings"
import Courses from "./Pages/Courses/index.js"
import { AuthProvider } from "./contex/AuthContex.js";
import PaymentForm from "./Pages/PaymentForm"
import CourseForm from "./Pages/CourseForm"
import AdminPannel from "./Pages/AdminPannel"
import PlayList from "./Pages/PlayList"
import VideoList from "./Pages/VideoList"
import GoogleAuth from "./Pages/GoogleAuth"
import NoPagesFound from "./components/NoPagesFound/index.js";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to top of the page
  }, [pathname]);  // When pathname changes, trigger the effect

  return null;
};



const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
function App() {
  const [darkMode, setDarkMode] = useState(false);
  
  return (
    <AuthProvider>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Router >
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="" element={
              <Body>
                <HeroSection />
                <WhatWeDo />
              </Body>
            } />
            <Route path="applyform/:role/:isfulltime" element={<ApplyForm />} />
            <Route path="traningform/:role/:isfulltime" element={<TraningForm />} />
            <Route path="courseform/:role/:courseId/:price" element={<CourseForm  />} />
            <Route path="hackathonform/:role" element={<HackathonForm />} />
            <Route path="career" element={<Career />} />
            <Route path="hackathon" element={<Hackathon/>} />
            <Route path="tranings" element={<Tranings />} />
            <Route path="courses" element={<Courses/>} />
            <Route path="aminurmus-payment" element={<PaymentForm />} />
            <Route path="admin-pannel" element={<AdminPannel/>} />
            <Route path="my-courses" element={<PlayList/>} />
            <Route path="playlist/video/:course/:courseId" element={<VideoList />} />
            <Route path="google-auth/:token" element={<GoogleAuth/>} />
            <Route path="*" element={<NoPagesFound/>} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </AuthProvider>

  );
}

export default App;
