import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import styles from './myStyles.module.css'; 
const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  //background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%);
`;


const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
  font-size: 22px;
  color: black;
  font-weight: 800;
`;

function Footer() {
  return (
    <div>
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4 }} columns={{ xs: 4, sm: 10, md: 17 }}>
    <Grid item md={4} sm={6}  xs={4}  style={{color:'white'}}>
          <Box className={`${styles.alignCenter} ${styles.footerHeading}`}>
            Aminurmus Technology Pvt Ltd.
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          Think Code Deploy
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          CIN- U62011WB2024PTC272711
          </Box>          
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          Regd NO- 272711
          </Box>
    </Grid>
    <Grid item md={2} sm={4} xs={4} style={{color:'white'}}>    
    <Box className={`${styles.alignCenter} ${styles.footerHeading}`}>
  Important links
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>          
          <a href='#services' > Home</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > About Us</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`} >
          
          <a href='#services' > Services</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Team</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          <a href='#services' > Careers </a>
          </Box>
    </Grid>
    <Grid item md={3} sm={6} xs={4} style={{color:'white'}}>
    
    <Box className={`${styles.alignCenter} ${styles.footerHeading}`}>
    Our Services
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > AI & ML</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          <a href='#services' >  Web Development</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Android Developement</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          <a href='#services' >SEO Optimization </a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Management Software</a>
          </Box>
    </Grid>
    <Grid item md={3} sm={4} xs={4} style={{color:'white'}}>
    
    <Box className={`${styles.alignCenter} ${styles.footerHeading}`}>
    FAQ
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Portfolio</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Privacy Policy</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Terms & Conditions</a>
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
           <a href='#services' > Support</a>
          </Box>
    </Grid>
    <Grid item md={5} sm={8} xs={4} style={{color:'white'}}>    
    <Box className={`${styles.alignCenter} ${styles.footerHeading}`}>
    Contact Us
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
          <LocalPhoneIcon/> +91 3533574525
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
         <EmailIcon/>info@aminurmus.com
          </Box>
          <Box className={`${styles.alignCenter} ${styles.footerItemHeading}`}>
            <LocationOnRoundedIcon/>
            Banglore, Karnataka - 560102,
            India ( We Work Remotely )
          </Box>               
    
            
    </Grid>
    </Grid>
    <Box style={{display:'flex',justifyContent:"space-around",flexWrap: "wrap"}}>
          <img src="https://1.bp.blogspot.com/-5m5OvWN8BuM/Xd6SGXrddiI/AAAAAAABd4Y/-fZleUhV3U471cFQl90YROd5DKylz5MUwCLcBGAsYHQ/s1600/Startup-India-Logo.jpg"
           alt="Logo" style={{ width: '9rem', height: '8rem',marginLeft:"15px" }} /> 
            <img src="https://bl-i.thgim.com/public/incoming/1q3lle/article65854290.ece/alternates/FREE_1200/mca-logo.JPG"
           alt="Logo" style={{ width: '9rem', height: '8rem', marginLeft:"15px" }} />  
           <img src="https://th.bing.com/th/id/OIP.vM9h2VR-zHXl4cvQhv4ukwHaHa?rs=1&pid=ImgDetMain"
           alt="Logo" style={{ width: '9rem', height: '8rem', marginLeft:"15px" }} /> 
            <img src="https://th.bing.com/th/id/OIP.Wkg-JmNE42RGZOKi5AB5rwHaE8?rs=1&pid=ImgDetMain"
           alt="Logo" style={{ width: '9rem', height: '8rem', marginLeft:"15px" }} />  
           <img src="https://th.bing.com/th/id/OIP.st6DLQruAsNAbnGEswFYvgHaE8?rs=1&pid=ImgDetMain"
           alt="Logo" style={{ width: '9rem', height: '8rem', marginLeft:"15px" }} />  
            </Box> 
    <Copyright>
        &copy; 2024 Aminurmus Technology. All Right Reserved
      </Copyright>
  </div>
   
  );
}

export default Footer;