import React, { useEffect, useState } from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box, FormControl, InputLabel, NativeSelect, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserImage from './user.png'
import axios from 'axios';
import {BASE_URL} from "../../constant"
const Blog = () => {
    const [blogs,setBlogs]=useState([])
    const [blogsTitle,setBlogsTitle]=useState([])
    const [blogsTitleValue,setBlogsTitleValue]=useState("")
    const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('latest');
  const [filteredBlogs,setFilteredBlogs]=useState([])
    const fetchData=async()=>{
        try {
            const {data} = await axios.get(`${BASE_URL}/course-form/get-all-blog`,{
                withCredentials:true
              });
              console.log(data?.blogs)
              if(!(data?.success)){
                return
              }
              setBlogs(data?.blogs)
              setFilteredBlogs(data?.blogs)
        } catch (error) {
            console.log({error})
        }
    }
    const fetchDataMenu=async()=>{
      try {
          const {data} = await axios.get(`${BASE_URL}/course/get-all-blog/all-title`,{
              withCredentials:true
            });
            if(!(data?.success)){
              return
            }
            setBlogsTitle(data?.blogs)
      } catch (error) {
          console.log({error})
      }
  }
    useEffect(()=>{
        fetchData()
        fetchDataMenu()
    },[])
    const handleGivePermission=async(courseId,userId)=>{
      try {
        const {data}=await axios.get(`${BASE_URL}/course-permission/add-purchase/${courseId}/${userId}`,{
          withCredentials:true
        })
        if(!(data?.success)){
          return
        }
        fetchData()
      } catch (error) {
        
      }
    }
    const handleRemovePermission=async(courseId,userId)=>{
      try {
        const {data}=await axios.get(`${BASE_URL}/course-permission/remove-purchase/${courseId}/${userId}`,{
          withCredentials:true
        })
        if(!(data?.success)){
          return
        }
        fetchData()
      } catch (error) {
        
      }
    }
    const applyFilter = () => {
      let filteredUsers = blogs.filter((item) => {
        const matchesSearchQuery =
          item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.name.toLowerCase().includes(searchQuery.toLowerCase());
  
        const matchesBlogTitle =
          blogsTitleValue === '' || item.title === blogsTitleValue;   
        return matchesSearchQuery && matchesBlogTitle;
      });
  
      filteredUsers = filteredUsers.sort((a, b) => {
        if (sortOrder === 'latest') {
          return new Date(b.createdAt) - new Date(a.createdAt);
        } else {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
      });
  
      setFilteredBlogs(filteredUsers);
    };
    useEffect(() => {
      const time = setTimeout(() => {
        applyFilter();
      }, 500);
      return () => clearTimeout(time);
    }, [searchQuery, sortOrder, blogs, blogsTitleValue]);
  return (
    <div  >      
 <div style={{margin:'30px 0px',display:'flex',justifyContent:'center'}}>
        <div  style={{width:'30%'}}>
        <TextField
            id="outlined-basic"
            label="Search by name or email"
            variant="outlined"
            style={{ width: '100%' }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
        </div>
        <div style={{ display: 'flex', width: '40%', marginLeft: '12px' }}>
        <FormControl fullWidth style={{ width:'80%',margin: '16px 8px'}}>      
      <NativeSelect
        value={blogsTitleValue}
        onChange={(e) => setBlogsTitleValue(e.target.value)}
      >
         <option value="" style={{textAlign:'center'}}>Select a blog title</option>
        {blogsTitle.map((blog) => (
          <option key={blog._id} value={blog.title} style={{textAlign:'center'}}>
            {blog.title}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
          <FormControl fullWidth style={{ margin: '0px 8px',width:'20%' }}>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Sort
            </InputLabel>
            <NativeSelect
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)} 
            >
              <option value={"latest"} style={{textAlign:'center'}}>Latest</option>
              <option value={"earliest"} style={{textAlign:'center'}}>Earliest</option>
            </NativeSelect>
          </FormControl>
          
        </div>
      </div>
      <div  className={styles.container}>
        {filteredBlogs.map((post, index) => (<>
          <div  className={styles.blogPost} key={post?._id}>
            <div style={{display:'flex',justifyContent:'center'}}>
            <img src={UserImage} alt={post.title} style={{height:'200px',width:'200px'}} />
            </div>
            <h5><span>Appled For: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.title}</span> </h5>
            <h5><span>name: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.name}</span> </h5>
            <h5><span>email: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.email} </span></h5>
            <h5><span>phone No: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.phoneNumber}</span> </h5>
            <h5><span>collage: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.collage}</span> </h5>            
            <h5><span>year: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.year}</span> </h5>            
            <h5><span>collageDegree: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.collageDegree}</span> </h5>            
            <h5><span>transactionId: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.transactionId}</span> </h5>            
            <h5><span>screenShot: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.transactionId}</span> </h5>  
            <h5><span>Permission: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.permission}</span> </h5>              
            <div style={{display:'flex',justifyContent:'center'}}>
            <a  className={styles.btnLink} onClick={() => handleGivePermission(post.courseId,post.userId)} target="_blank" style={{width:"115px",height:'40px'}}>Permission</a>
            <a  className={styles.btnLink} onClick={() => handleRemovePermission(post.courseId,post.userId)} target="_blank" style={{width:"115px",height:'40px',marginLeft:'8px'}}>Remove</a>
            
   </div>
          </div>
          </>))}
      </div>
    </div>
  );
};

export default Blog;