import React, { useContext, useEffect, useState } from 'react';
import styles from './myStyles.module.css';
import Typewriter from 'typewriter-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContex } from '../../contex/AuthContex';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import axios from 'axios';
import {BASE_URL} from "../../constant"


const ContactForm = styled.form`
  width: 60%;
  display: flex;  
  margin:auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
  border:4px solid #2cb015;  
  @media only screen and (max-width: 768px){
        width: 80%;
    }
   @media only screen and (max-width: 500px){
        width: 95%;
    }
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
export const NavBtn = styled.a`
  justify-content: center;
  margin:8px;
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(225deg, hsla(260, 100%, 50%, 1) 0%, hsla(550, 100%, 50%, 1) 100%);
  color: white;
  height: 70%;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.6s ease-in-out; 
  :hover {
      color: black;     
    }
  
`;
function BlogComponent({ post, fetchData, courseId }) {
    const [isEdit, setIsEdit] = useState(false)
    const [isSave, setIsSave] = useState(false)
    const { isAdmin, setIsAdmin, isLogin, setIsLogin } = useContext(AuthContex)
    const [form,setForm]=useState(post)
    
    const handleEdit = () => {
        setIsEdit(true)
        setIsSave(true)
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData=new FormData()
    if (form?.videoSrc) {
      formData.append('file', form.videoSrc);
    }
      formData.append('title',form?.title)
      formData.append('lectures',form?.lectures)
      try {
         const {data} = await axios.put(`${BASE_URL}/course-playlist/edit-playlist/${courseId}/${post._id}`, formData,{
          withCredentials:true
        });
        if(!(data?.success)){
          return
        }
        setIsEdit(false); 
        setIsSave(false);
        await fetchData();
      } catch (error) {
      }
    };
    const handleDelete = async (e) => {
      e.preventDefault();     
      try {
         const {data} = await axios.delete(`${BASE_URL}/course-playlist/remove-playlist/${courseId}/${post._id}/${post.videoSrc}`,{
          withCredentials:true
        });
        if(!(data?.success)){
          return
        }
        await fetchData();  
      } catch (error) {
      }
    };
    console.log({form})
    const navigate = useNavigate();
    return (
        <div className={styles.blogPost} key={post?._id}>
            <div>
            {!isEdit ? (
                    <video src={`${BASE_URL}/Images/${post.videoSrc}`} alt={post.title} controls style={{ width: "100%" }} />
                ) : (
                  <TextField
                  id="standard-error-helper-text"
                  placeholder="Video Src"
                  variant="standard"
                  type='file'
                  onChange={(e) => setForm({...form,videoSrc:e.target.files[0]})}
                />
                )}
                    </div>
            <h2 style={{ fontWeight: 800, fontSize: '14px' }}>
                {!isEdit ?
                    <>{post.title}</> :
                    <TextField
                        id="standard-error-helper-text"
                        variant="standard"
                        value={form.title}
                        style={{ width: "100%", marginTop: "8px" }}
                        onChange={(e) =>setForm({...form,title:e.target.value})}
                    />}
            </h2>
            <h5><span> Lectures Number: </span>
                {!isEdit ? <>{post.lectures}</> :
                    <TextField
                        id="standard-error-helper-text"
                        variant="standard"
                        value={form.lectures}
                        style={{ width: "90%", marginTop: "8px" }}
                        onChange={(e) =>setForm({...form,lectures:e.target.value})}
                    />}
            </h5>           
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    isAdmin ?
                        <>{!isEdit &&
                            <span className={styles.btnLink} onClick={() => handleEdit()} style={{ width: "115px", height: '40px' }}>Edit</span>}
                            {isSave && <span className={styles.btnLink} style={{ width: "115px", height: '40px' }} onClick={(e)=>handleSubmit(e)}>Save</span>}</> :
                        <a className={styles.btnLink} onClick={() => navigate(`/courseform/${post.title}`)} target="_blank" style={{ width: "115px", height: '40px' }}>Enroll Now</a>
                }
                {
                    isAdmin &&
                    <a className={styles.btnLink} onClick={(e) => handleDelete(e)} target="_blank" style={{ width: "115px", height: '40px', marginLeft: '8px' }}>Delete</a>
                } </div>
        </div>
    )
}

const Blog = () => {
    const { isAdmin, setIsAdmin, isLogin, setIsLogin } = useContext(AuthContex)
    const [openModelAddCourse, setOpenModelAddCourse] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
    const { course } = useParams();
    const { courseId } = useParams();
    const [blogs,setBlogs]=useState([])
    const [form, setForm] = useState({
        videoSrc:"",
      title:"",
      lectures:""
      })
      const [isError, setIsError] = useState({
        isTitleError:false,
     isVideoSrcError:false,
     isLecturesError:false,

      })
      const [submitFormError, setSubmitFormError] = useState({
        success: false,
        loading: false,
        error: false,
        isDataFilled: false
      })
      const handleChange = (e, value) => {
        if (value === "videoSrc") {
          if (e.target.value.length === 0 || e.target.value.length <= 3) {
            setIsError({ ...isError, isVideoSrcError: true })
          } else {
            setIsError({ ...isError, isVideoSrcError: false })
          }
          setForm({ ...form, videoSrc: e.target.value })
        }else if (value === "title") {
          if (e.target.value.length === 0 || e.target.value.length <= 3) {
            setIsError({ ...isError, isTitleError: true })
          } else {
            setIsError({ ...isError, isTitleError: false })
          }
          setForm({ ...form, title: e.target.value })
        } else if (value === "lectures") {
            if (e.target.value.length === 0 || e.target.value.length <= 1) {
              setIsError({ ...isError, isLecturesError: true })
            } else {
              setIsError({ ...isError, isLecturesError: false })
            }
            setForm({ ...form, lectures: e.target.value })
          }
      }
     
    const fetchData=async()=>{
        try {
            const {data} = await axios.get(`${BASE_URL}/course-playlist/get-all-playlists/${courseId}`,{
                withCredentials:true
              });
              console.log(data?.blogs)
              if(!(data?.success)){
                return
              }
              setBlogs(data?.blogs)
        } catch (error) {
            console.log({error})
        }
    }
    useEffect(()=>{
        fetchData()
    },[])   
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (submitFormError.loading) return;
      const fillAllData=Object.values(isError).some(value => value === true);
      const filled=Object.values(form).some(value => value.length === 0)
      if (fillAllData || filled) {
        setSubmitFormError({
          ...submitFormError, isNotDataFilled: true
        })
        return
      }
      
      try {
        setSubmitFormError({ ...submitFormError, loading: true,isNotDataFilled:false })
        const formData=new FormData()
      formData.append('file',form?.videoSrc)
      formData.append('title',form?.title)
      formData.append('lectures',form?.lectures)
         const {data} = await axios.post(`${BASE_URL}/course-playlist/add-playlist/${courseId}`, formData,{
          withCredentials:true
        });
        if(!(data?.success)){
          setSubmitFormError({ ...submitFormError, loading: false, error: true })
          return
        }
        setOpenModelAddCourse(false)
        await fetchData()
  
      } catch (error) {
        setSubmitFormError({ ...submitFormError, loading: false, error: true })
      }
    };

    return (
        <div>
            <Box className={styles.textLoop}>
                {course}
               
            </Box>
            <div style={{ display: 'flex', justifyContent: "center" }}>
                {
                    isAdmin &&
                    <span className={styles.btnLink} onClick={()=>setOpenModelAddCourse(true)} style={{ width: "115px", height: '40px' }}>Add Video</span>
                }
            </div>
            <div className={styles.container}>
                {blogs.map((post, index) => (
                    <BlogComponent post={post} fetchData={fetchData} courseId={courseId}/>
                ))}
            </div>

            <Modal
      open={openModelAddCourse}
      onClose={() => setOpenModelAddCourse(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >      
        <ContactForm >
          <ContactTitle>Add Video</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Video"
            variant="standard"
            type='file'
            onChange={(e) => setForm({...form,videoSrc:e.target.files[0]})}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Title"
            variant="standard"
            value={form?.title}
            error={isError?.isTitleError}
            helperText={isError?.isTitleError && "Pls enter validate title"}
            onChange={(e) => handleChange(e, "title")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Lectures Number"
            variant="standard"
            value={form?.lectures}
            error={isError?.isLecturesError}
            helperText={isError?.isLecturesError && "Pls enter validate lectures"}
            onChange={(e) => handleChange(e, "lectures")}

          />
          <NavBtn variant="outlined" onClick={(e) => handleSubmit(e)}>Add</NavBtn>
          {
            submitFormError.success &&
            <Alert severity="success" color="success">
              Your Application has been submitted successfully
            </Alert>
          }
          {
            submitFormError.error &&
            <Alert severity="error" color="error">
              {errorMsg}
            </Alert>
          }
          {
            submitFormError.isNotDataFilled &&
            <Alert severity="error" color="error">
              Please fill proper data
            </Alert>
          }
        </ContactForm>
    </Modal>
        </div>
    );
};

export default Blog;