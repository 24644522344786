
import { Nav, NavLink,NavBtn, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import myImage from './logo.png';
import { PopupWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import styled from 'styled-components'
import axios from 'axios';
import { AuthContex } from '../../contex/AuthContex';
import { jwtDecode } from "jwt-decode";
import {BASE_URL} from "../../constant"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,  
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ContactForm = styled.form`
  width: 60%;
  display: flex;  
  margin:auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
  border:4px solid #2cb015;  
  @media only screen and (max-width: 768px){
        width: 80%;
    }
   @media only screen and (max-width: 500px){
        width: 95%;
    }
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAdmin,setIsAdmin,isLogin,setIsLogin } = useContext(AuthContex)
  const [openModelSignIn, setOpenModelSignIn] = useState(false)
  const [openModelLogIn, setOpenModelLogIn] = useState(false)
  const [logOut, setLogOut] = useState(false)
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
  const navigate = useNavigate()
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    cPassword: ""
  })
  const [isError, setIsError] = useState({
    isNameError: false,
    isEmailError: false,
    isPasswordError: false,
    isCPasswordError: false,
  })
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isDataFilled: false
  })
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const theme = useTheme();
  const calendlyRef = useRef();
  const handleClick = (value) => {
    setIsOpen(!isOpen);
  };
  console.log({ isLogin })
  const handleLogInModal = () => {
    setOpenModelLogIn(true)
    setForm({
      name: "",
      email: "",
      password: "",
      cPassword: ""
    })
    setIsError({
      isNameError: false,
      isEmailError: false,
      isPasswordError: false,
      isCPasswordError: false,
    })
    setSubmitFormError({
      success: false,
      loading: false,
      error: false,
      isDataFilled: false
    })
  }
  const handleGoogleSuccess = () => {
    window.open(
      `${ BASE_URL}/auth/google/callback`,
      "_self"
    );
  };
  const handleSignInModal = () => {
    setOpenModelSignIn(true)
    setOpenModelLogIn(false)
    setForm({
      name: "",
      email: "",
      password: "",
      cPassword: ""
    })
    setIsError({
      isNameError: false,
      isEmailError: false,
      isPasswordError: false,
      isCPasswordError: false,
    })
    setSubmitFormError({
      success: false,
      loading: false,
      error: false,
      isDataFilled: false
    })
  }
  const handleClose = () => {
    setOpenModelSignIn(false)
    setOpenModelLogIn(false)
  }
  const handleChange = (e, value) => {
    if (value === "name") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isNameError: true })
      } else {
        setIsError({ ...isError, isNameError: false })
      }
      setForm({ ...form, name: e.target.value })
    } else if (value === "email") {
      if (e.target?.value && e.target.value.match(isValidEmail)) {
        setIsError({ ...isError, isEmailError: false })
      } else {
        setIsError({ ...isError, isEmailError: true })
      }
      setForm({ ...form, email: e.target.value })
    } else if (value === "password") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isPasswordError: true })
      } else {
        setIsError({ ...isError, isPasswordError: false })
      }
      setForm({ ...form, password: e.target.value })
    } else if (value === "cpassword") {
      if ((form?.password !== e.target.value)) {
        setIsError({ ...isError, isCPasswordError: true })
      } else {
        setIsError({ ...isError, isCPasswordError: false })
      }
      setForm({ ...form, cPassword: e.target.value })
    }
  }
  const handleSubmit = async (e) => {
    if (submitFormError.loading) return;
    if (isError?.isNameError || isError?.isEmailError || isError?.isPasswordError || isError?.isCPasswordError) {
      setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      return
    }
    if (form?.email.length === 0 || form.password.length === 0) {
      setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      if ((form.name.length === 0 || form.cPassword.length === 0) && openModelSignIn) {
        setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      }
      return
    }
    setSubmitFormError({ ...submitFormError, loading: true })
    try {
      const scriptURL = openModelSignIn ? `${BASE_URL}/auth/signup` : `${ BASE_URL}/auth/login`;
      const { data } = await axios.post(scriptURL, form,{
        withCredentials:true
      })
      if (data?.success) {
        if (openModelLogIn) {
          localStorage.setItem("accessToken", JSON.stringify(data?.accessToken))
          const decoded = jwtDecode(data?.accessToken);
          if(decoded?.role==="admin"){
            setIsAdmin(true)
          }
          setIsLogin(true)
          setOpenModelLogIn(false)
        } else {
          setOpenModelSignIn(false)
          setOpenModelLogIn(true)
        }
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setErrorMsg(data?.msg)
      } else {
        setErrorMsg(data?.msg)
        setTimeout(()=>{
          setSubmitFormError({ ...submitFormError, loading: false, error: true })
        },1000)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  };
  useEffect(()=>{
    const accessToken=JSON.parse(localStorage.getItem("accessToken"))
    if(accessToken){
      setIsLogin(true)
      const decoded = jwtDecode(accessToken);
          if(decoded?.role==="admin"){
            setIsAdmin(true)
          }
    }else{
      setIsLogin(false)
    }
  },[])
  const handleLogOut = () => {
    setIsLogin(false)
    setIsAdmin(false)
    localStorage.removeItem("accessToken")
    window.location.href = '/';
  }
  return (<>
    <Modal
      open={openModelLogIn}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <ContactForm >
          <ContactTitle>SignIn</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Email"
            variant="standard"
            value={form?.email}
            error={isError?.isEmailError}
            helperText={isError?.isPasswordError && "Pls enter validate email"}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Password"
            variant="standard"
            type="password"
            value={form?.password}
            error={isError?.isPasswordError}
            helperText={isError?.isPasswordError && "Pls enter validate password"}
            onChange={(e) => handleChange(e, "password")}
          />
          <NavBtn style={{margin:'0px'}} variant="outlined" onClick={(e) => handleSubmit()}>SignIn</NavBtn>
          <Box style={{ textAlign: "center", marginTop: "10px" }}>
            Don't have an account? <span style={{ color: 'blue',cursor:"pointer" }}onClick={() => handleSignInModal()}>Signup</span>
          </Box>

          <Box style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <Button variant="outlined" startIcon={<GoogleIcon />} onClick={handleGoogleSuccess}>
              Login with Google
            </Button>
          </Box>
        </ContactForm>
        {
          submitFormError.success &&
          <Alert severity="success" color="success" style={{margin:'auto',width:'60%'}}>
            Your Application has been submitted successfully
          </Alert>
        }
        {
          submitFormError.error &&
          <Alert severity="error" color="error" style={{margin:'auto',width:'60%'}}>
            {errorMsg}
          </Alert>
        }
        {
          submitFormError.isDataFilled &&
          <Alert severity="error" color="error" style={{margin:'auto',width:'60%'}}>
            Please fill proper data
          </Alert>
        }
      </>
    </Modal>
    <Modal
      open={openModelSignIn}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >      
        <ContactForm >
          <ContactTitle>SignUp</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Name"
            variant="standard"
            value={form?.name}
            error={isError?.isNameError}
            helperText={isError?.isPasswordError && "Pls enter validate name"}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Email"
            variant="standard"
            value={form?.email}
            error={isError?.isEmailError}
            helperText={isError?.isPasswordError && "Pls enter validate email"}
            onChange={(e) => handleChange(e, "email")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Password"
            variant="standard"
            type="password"
            value={form?.password}
            error={isError?.isPasswordError}
            helperText={isError?.isPasswordError && "Pls enter validate password"}
            onChange={(e) => handleChange(e, "password")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Comferm Password"
            variant="standard"
            type="password"
            value={form?.cPassword}
            error={isError?.isCPasswordError}
            helperText={isError?.isCPasswordError && "password not match"}
            onChange={(e) => handleChange(e, "cpassword")}
          />
          <NavBtn variant="outlined" onClick={(e) => handleSubmit()}>Sign UP</NavBtn>
          {
            submitFormError.success &&
            <Alert severity="success" color="success">
              Your Application has been submitted successfully
            </Alert>
          }
          {
            submitFormError.error &&
            <Alert severity="error" color="error">
              {errorMsg}
            </Alert>
          }
          {
            submitFormError.isDataFilled &&
            <Alert severity="error" color="error">
              Please fill proper data
            </Alert>
          }
        </ContactForm>
    </Modal>
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <span style={{ display: 'flex', alignItems: 'center', color: 'white', marginBottom: '20;', cursor: 'pointer' }} >
            <img src={myImage} alt="Logo" style={{ width: '5rem', height: '5rem' }} />
            <Span>Aminurmus Learnings-Tranings</Span>
          </span>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>
        <NavItems>
          <NavLink onClick={() => navigate("/courses")} >Courses</NavLink>
          <NavLink onClick={() => navigate("/career")}>Careers</NavLink>
          <NavLink onClick={() => navigate("/tranings")}>Tranings</NavLink>
          <NavLink onClick={() => navigate("/hackathon")}>Hackathons</NavLink>
          <NavLink onClick={() => navigate("/")}>About-US</NavLink>
          {isLogin &&
          <NavLink onClick={() => navigate("/my-courses")}>My-Course</NavLink>
          }
          {
            isAdmin && <NavLink onClick={() => navigate("/admin-pannel")}>Admin-Pannel</NavLink>
          }
          <div style={{display:"flex",position:"absolute",right:"0px"}}>
            {
              isLogin ?<>
              <NavBtn onClick={()=>handleLogOut()} >LogOut</NavBtn>  
              </>:<>
              <NavBtn onClick={()=>setOpenModelLogIn(true)} >SignIn</NavBtn>          
              <NavBtn onClick={() => handleSignInModal()}>SignUp</NavBtn>
              </>
            }
          </div>
        </NavItems>
        <ButtonContainer>
        </ButtonContainer>
        {isOpen && (<>
          <MobileMenu isOpen={isOpen}>
            <MobileLink onClick={() => navigate("/courses")} >Courses</MobileLink>
            <MobileLink onClick={() => navigate("/career")} >Careers</MobileLink>
            <MobileLink onClick={() => navigate("/tranings")} >Tranings</MobileLink>
            <MobileLink onClick={() => navigate("/hackathon")}>Hackathons</MobileLink>
            <MobileLink onClick={() => navigate("/")}>About-US</MobileLink>
            {
              isLogin && <MobileLink onClick={() => navigate("/my-courses")}>My-Course</MobileLink>
            }
            {
              isAdmin && <MobileLink onClick={() => navigate("/admin-pannel")} >Admin-Pannel</MobileLink>
            }          
            
            {
              isLogin ?
              <MobileLink><NavBtn onClick={()=>handleLogOut()} >LogOut</NavBtn></MobileLink>:
                <>
                  <MobileLink><NavBtn onClick={() => setOpenModelLogIn(true)} >SignIn</NavBtn></MobileLink>
                  <MobileLink ><NavBtn onClick={() => setOpenModelLogIn(true)} >SignUp</NavBtn></MobileLink>
                </>
            }
          </MobileMenu>
          </>
        )}
      </NavbarContainer>
      <CalendlyComponent ref={calendlyRef} />
      <Modal
        open={logOut}
        onClose={() => setLogOut(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Log-Out
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure want to Log-Out
          </Typography>
          <Button variant="outlined" sx={{ mt: 2 }} onClick={() => handleLogOut()} >Comferm</Button>

        </Box>
      </Modal>
    </Nav>
  </>
  );
};
const CalendlyComponent = forwardRef((_, ref) => (
  <div className="popup-widget">
    <PopupWidget
      url="https://calendly.com/aminurmustechnology/30min"
      rootElement={document.getElementById('root')}
      text="Let's Connect"
      textColor="#ffffff"
      color="-webkit-linear-gradient(225deg, hsla(259, 100%, 50%, 1) 0%, hsla(489, 100%, 50%, 1) 100%)"
    />
    <button ref={ref} style={{ display: 'none' }}>Trigger Calendly</button>
  </div>
));
export default Navbar;