import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import styles from './styles.module.css';
function Media() {
  return (
    <Grid  className={styles.container}>
      {Array.from(new Array(6)).map((_, index) => (
        <Box key={index} className={styles.blogPost}>
          <Skeleton variant="rectangular" width={"100%"} height={118} />
          <Box sx={{ pt: 0.5 }}>
            <Skeleton width="60%"/>
            <Skeleton width="50%" />
            <Skeleton width="40%" />
            <Skeleton width="60%" />
            
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

export default function YouTube() {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Media />
    </Box>
  );
}
