import { Alert, AlertTitle, Box, Button, FormControl, InputLabel, MenuItem, NativeSelect, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import PaymentQR from './qrPhoto.jpeg'
import jsPDF from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';
import Logo from './logo.png';
import Sign from "./sign.jpg"
import {BASE_URL} from "../../constant"



const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 8px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
    &::placeholder{
       padding-left:12px;
    }
`

const ContactButton = styled.input`
  width: 20%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;  
   @media (max-width: 1230px) {
    width: 50%;
    padding: 13px 16px;
  }
    @media (max-width: 640px) {
   width: 50%;
    padding: 13px 16px;
  }
`
export default function Index() {
    const { isfulltime } = useParams();
    const [duration, setDuration] = useState([])
    const [title, setTitle] = useState("")
    const [myYear,setMyYear]=useState(0)
    const [position,setPostion]=useState([])
    const [selectedPostion,setSelectedPostion]=useState("")
    const [selectedDuration,setSelectedDuration]=useState("")
    const [isError, setIsError] = useState({
        isNameError: false,
        isEmailError: false,
        isPhoneError: false,
        isCollageError: false,
        isAddressError: false,
        isDepartmentError: false
      })
    const [submitFormError, setSubmitFormError] = useState({
        success: false,
        loading: false,
        error: false,
        isDataFilled: false
    })
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: "se",
        date: new Date().toLocaleDateString(),
        phone: '',
        amount: "",
        college: '',
        department: '',
        year: '',
        address: '',
    });
    const [isPaymentBtn, setIsPaymentBtn] = useState(false)
    const [amount, setAmount] = useState("")
    const [paymentData, setPaymentData] = useState({})
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const year = [1, 2, 3, 4, 5]
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitFormError.loading) return;
    const fillAllData=Object.values(isError).some(value => value === true);
    if (fillAllData || myYear===0) {
      setSubmitFormError({
        ...submitFormError, isNotDataFilled: true
      })
      return
    }
    
    try {
      setSubmitFormError({ ...submitFormError, loading: true,isNotDataFilled:false })
       const {data} = await axios.post(`${BASE_URL}/payment-form/create-blog`,
        {
          ...formData,amount,title,duration:selectedDuration
        },{
        withCredentials:true
      });
      if(!(data?.success)){
        setSubmitFormError({ ...submitFormError, loading: false, error: true })
        return
      }
      setSubmitFormError({ ...submitFormError, loading: false, success: true,isNotDataFilled:false })
      setMyYear(0)
      setFormData({
        name: '',
        email: '',
        position: "",
        date: new Date().toLocaleDateString(),
        phone: '',
        amount: "",
        college: '',
        department: '',
        year: '',
        address: '',
      })
      window.location.href = data?.url;
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (submitFormError.loading) return;
    //     if (formData?.name?.length == 0 || formData?.email?.length === 0
    //         || formData?.transaction_id?.length < 3 || formData?.phone?.length < 3 ||
    //         formData?.position?.length < 3
    //     ) {
    //         setSubmitFormError({
    //             ...submitFormError, isDataFilled: true
    //         })
    //         return
    //     }
    //     setSubmitFormError({
    //         ...submitFormError, isDataFilled: false
    //     })
    //     try {
    //         const data = new FormData();
    //         Object.keys(formData).forEach((key) => {
    //             data.append(key, formData[key]);
    //         });
    //         setSubmitFormError({ ...submitFormError, loading: true })
    //         const scriptURL = "https://script.google.com/macros/s/AKfycbyEy7MuELEjYS7gucIJSlKoXLdb45JQS_RGeRZokEbp9WYG48C6MTvssKpvED4u8tONzg/exec"
    //         const res = await fetch(scriptURL, {
    //             method: 'POST',
    //             body: data
    //         });
    //         setSubmitFormError({ ...submitFormError, loading: false, success: true })
    //         setIsPaymentBtn(true)
    //         setPaymentData(formData)
    //         setFormData({
    //             name: '',
    //             email: '',
    //             position: "",
    //             date: new Date().toLocaleDateString(),
    //             phone: '',
    //             amount: "",
    //             college: '',
    // department: '',
    // year: '',
    // address: '',
    //         })

    //     } catch (error) {
    //         setSubmitFormError({ ...submitFormError, loading: false, error: true })
    //     }
    // };
    const handleChange = (e, value) => {
        if (value === "name") {
            if (e.target.value.length === 0 || e.target.value.length <= 3) {
              setIsError({ ...isError, isNameError: true })
            } else {
              setIsError({ ...isError, isNameError: false })
            }
            setFormData({ ...formData, name: e.target.value })
          }else if (value === "email") {
            if (e.target?.value && e.target.value.match(isValidEmail)) {
              setIsError({ ...isError, isEmailError: false })        
            } else {
              setIsError({ ...isError, isEmailError: true })
            }
            setFormData({ ...formData, email: e.target.value })
          }else if (value === "phoneNumber") {
            if (e.target.value.length === 0 || e.target.value.length <= 9) {
              setIsError({ ...isError, isPhoneError: true })
            } else {
              setIsError({ ...isError, isPhoneError: false })
            }
            setFormData({ ...formData, phone: e.target.value })
          }else if (value === "position") {
            setFormData({ ...formData, position: e.target.value })
        } 
        else if (value === "collageYear") {
            setMyYear(e.target.value)
            if (e.target.value === 5) {
              setFormData({ ...formData, year: "pass out" })
            }else if (e.target.value === 6) {
              setFormData({ ...formData, year: "fresher" })
            }
             else if (e.target.value === 1) {
              setFormData({ ...formData, year: "1st Year" })
            } else if (e.target.value === 2) {
              setFormData({ ...formData, year: "2nd Year" })
            } else if (e.target.value === 3) {
              setFormData({ ...formData, year: "3rd Year" })
            } else if (e.target.value === 4) {
              setFormData({ ...formData, year: "4th Year" })
            }
          }
          else if (value === "collage" ) {
            if (e.target.value.length === 0 || e.target.value.length <= 3) {
              setIsError({ ...isError, isCollageError: true })
            } else {
              setIsError({ ...isError, isCollageError: false })
            }
            setFormData({ ...formData, college: e.target.value })
          } else if (value === "address" ) {
            if (e.target.value.length === 0 || e.target.value.length <= 3) {
              setIsError({ ...isError, isAddressError: true })
            } else {
              setIsError({ ...isError, isAddressError: false })
            }
            setFormData({ ...formData, address: e.target.value })
          }else if (value === "department" ) {
            if (e.target.value.length === 0 || e.target.value.length <= 3) {
              setIsError({ ...isError, isDepartmentError: true })
            } else {
              setIsError({ ...isError, isDepartmentError: false })
            }
            setFormData({ ...formData, department: e.target.value })
          }       

    }
    const downloadReceipt = () => {
        const doc = new jsPDF();

        // Add logo
        doc.addImage(Logo, 'PNG', 10, 10, 30, 30); // Position and size of the logo

        // Add institute information (customize the address and other details)
        doc.setFontSize(12);
        doc.text(
            'Aminurmus Technology Pvt Ltd.',
            doc.internal.pageSize.getWidth() / 2,
            20,
            { align: 'center' }
        );

        doc.text(
            'Address: Banglore, Karnataka - 560102, India (We Work Remotely)',
            doc.internal.pageSize.getWidth() / 2,
            30,
            { align: 'center' }
        );

        // Title of the receipt
        doc.setFontSize(16);
        doc.text('Payment Receipt', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

        // Add payment details using formData
        doc.setFontSize(14);
        const margin = 20;
        const lineHeight = 10;
        const lineSpacing = 6; // Extra space between fields and the lines

        // Function to draw field with label and bold value
        const drawField = (label, value, yPosition) => {
            // Regular font for label
            doc.setFont(undefined, 'bold');
            doc.text(`${label}:`, margin, yPosition);

            // Bold font for value
            doc.setFont(undefined, 'normal');
            doc.text(`${value || 'N/A'}`, margin + 40, yPosition); // Indent the value a bit for clarity

            // Draw line below the field
            doc.setFont(undefined, 'normal'); // Revert back to normal for the line
            doc.line(margin, yPosition + 2, doc.internal.pageSize.getWidth() - margin, yPosition + 2);
        };

        let yPos = 70; // Start Y position for the fields
        drawField('Name', paymentData.name, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Email', paymentData.email, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Phone Number', paymentData.phone, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Position', paymentData.position, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Duration', paymentData.duration, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Transaction ID', paymentData.transaction_id, yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Date', paymentData.date || new Date().toLocaleDateString(), yPos);
        yPos += lineHeight + lineSpacing;

        drawField('Amount', paymentData.amount, yPos);
        yPos += lineHeight + lineSpacing;

        // Add Signature
        doc.addImage(Sign, 'PNG', margin, yPos, 100, 30); // Add the signature image
        doc.text('Signature', margin, yPos + 40); // Position the signature label

        // Save the PDF
        doc.save('receipt.pdf');
    };

    const getPosition = async () => {
      try {
        const { data } = await axios.get(`${BASE_URL}/position/get-all-blog`,{
          withCredentials:true
        })
        if (data?.success) {
          setPostion(data?.blogs)
        }
      } catch (error) {
        setSubmitFormError({ ...submitFormError, loading: false, error: true })
      }
    }
    useEffect(() => {
      getPosition()   
    }, [])
    const handleSelectPosition=(value)=>{
      setSelectedPostion(value)
      let item=position.filter((item)=>item.title===value)
      console.log(item)
      setTitle(item[0]?.title)
     setDuration(item[0]?.durationPricing)
    }
    const handleSelectDuration=(value)=>{
      setSelectedDuration(value)
      let item=duration.filter((item)=>item.duration===value)
     setAmount(item[0]?.price)
    }
     
    return (
        <div style={{ textAlign: "center" }} >
            <div style={{ margin: "30px" }}>
                <span style={{ margin: "auto", fontSize: "30px", fontWeight: 600 }}>Internship/Full Time Registration Form</span>
            </div>
            <div style={{display:"flex",justifyContent:"center"}}>
            <iframe width="30%" height="200" 
            src="https://www.youtube.com/embed/AqhEux5lleE?si=2uRxK8w5HYJnEa-K"
             title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div style={{ marginTop: "30px" }}>
                <ul>
                    <li>Official Offer Letter</li>
                    <li>We will give you <span style={{ fontWeight: 600 }}>training</span> (Training fee applicable)
                    </li>
                    <li> You will get  <span style={{ fontWeight: 600 }}>stipend </span> based on project you complete</li>
                    <li>
                        Increment and Performance Awards with certificates recognizing your achievements</li>
                    <li>Professional Email Address: <span style={{ fontWeight: 600 }}>yourname@aminurmus.com</span></li>
                    <li>Opportunity for <span style={{ fontWeight: 600 }}>Pre-Placement Offer</span> (PPO) based on performance and the packege will be <span style={{ fontWeight: 600 }}>3.6 LPA to 4.25 LPA</span></li>
                    <li><span style={{ fontWeight: 600 }}>Internship Experience Certificate</span> at the end of the program</li>
                    <li>After your payments within 24 hour you will onboard with offer letter with official mail ids. And you will get project details and will start  training program.</li>
                    <li style={{ fontWeight: 600 }}>Note- This fee is for training and CRM Team and Documentation for getting client project and all maintanance.</li>
                </ul>
            </div>
            <Box style={{ display: "flex", justifyContent: "center" }}>
                <ContactForm >
                    <ContactTitle>Payment Form
                    </ContactTitle>
                    <TextField
            error={isError?.isNameError}
            id="standard-error-helper-text"
            label="Name*"
            helperText={isError?.isNameError && "Pls enter your name"}
            variant="standard"
            value={formData?.name}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            error={isError?.isEmailError}
            id="standard-error-helper-text"
            label="Email*"
            helperText={isError?.isEmailError && "Pls enter validate email"}
            variant="standard"
            value={formData?.email}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            error={isError?.isPhoneError}
            id="standard-error-helper-text"
            label="Phone Number*"
            helperText={isError?.isPhoneError && "Pls enter your valid number"}
            variant="standard"
            value={formData?.phone}
            onChange={(e) => handleChange(e, "phoneNumber")}
          />
                    <TextField
            error={isError?.isAddressError}
            id="standard-error-helper-text"
            label="Address(Aadhaar Card)*"
            helperText={isError?.isAddressError && "Pls enter your address"}
            variant="standard"
            value={formData?.address}
            onChange={(e) => handleChange(e, "address")}
          />
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              error={isError?.isCollageError}
              id="standard-error-helper-text"
              label="College*"
              helperText={isError?.isCollageError && "Pls enter your collage"}
              variant="standard"
              value={formData?.college}
              onChange={(e) => handleChange(e, "collage")}
              style={{ width: "70%", marginTop: "8px" }}
            />
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-standard-label">Select Year</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={myYear}
                label="Year*"
                onChange={(e) => handleChange(e, "collageYear")}
              >            
                  <MenuItem value={1}>1st year</MenuItem>
                  <MenuItem value={2}>2nd year</MenuItem>
                  <MenuItem value={3}>3rd year</MenuItem>
                  <MenuItem value={4}>4th year</MenuItem>
                  <MenuItem value={5}>pass out </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            error={isError?.isDepartmentError}
            id="standard-error-helper-text"
            label="College Degree (ex: Btech/MCA/BCA - CS)*"
            helperText={isError?.isDepartmentError && "Pls enter your engineering branch"}
            variant="standard"
            value={formData?.department}
            onChange={(e) => handleChange(e, "department")}
          />
                     <FormControl fullWidth style={{ width:'80%',margin: '16px 0px'}}>   
                     <div style={{display:'flex',justifyContent:"space-between"}}>
                      
      <NativeSelect
        value={selectedPostion}
        style={{width:"60%"}}
        onChange={(e) =>handleSelectPosition(e.target.value)}
      >
         <option value="" style={{textAlign:'center'}}>Select Postion </option>
        {position.map((blog) => (
          <option key={blog._id} value={blog.title} style={{textAlign:'center'}}>
            {blog.title}
          </option>
        ))}
      </NativeSelect>
      <NativeSelect
        value={selectedDuration}
        onChange={(e) =>handleSelectDuration(e.target.value)}
      >
         <option value="" style={{textAlign:'center'}}>Select Duration </option>
        {duration.map((blog) => (
          <option key={blog._id} value={blog.duration} style={{textAlign:'center'}}>
            {blog.duration}
          </option>
        ))}
      </NativeSelect>
      </div>  
    </FormControl>                 
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <ContactButton type="submit" value={`Pay ${amount}`} onClick={(e) => handleSubmit(e)} style={{ background: submitFormError.loading && "grey", color: submitFormError.loading && 'white' ,padding:"12px 22px",width:'160px'}} />
                    </Box>
                    {
                        submitFormError.success &&
                        <Alert severity="success" color="success">
                            Your Payment has been submitted successfully Pls download your receipt
                        </Alert>
                    }
                    {
                        submitFormError.error &&
                        <Alert severity="error" color="error">
                            Something Wrong
                        </Alert>
                    }
                    {
                        submitFormError.isDataFilled &&
                        <Alert severity="error" color="error">
                            Please fill all data
                        </Alert>
                    }
                    {
                        isPaymentBtn &&
                        <Box>
                            <Button variant="contained" startIcon={<DownloadIcon />} onClick={downloadReceipt}>  Download Receipt
                            </Button>
                        </Box>
                    }
                </ContactForm>

            </Box>
        </div>
    )
}