import React, { useContext, useEffect, useState } from 'react';
import styles from './myStyles.module.css';
import Typewriter from 'typewriter-effect';
import { useNavigate } from 'react-router-dom';
import { AuthContex } from '../../contex/AuthContex';
import LoadingPages from "../../components/LoadingPages"
import { Alert, Box, Button, FormControl, InputLabel, Modal, NativeSelect, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import axios from 'axios';
import NoPagesFound from '../../components/NoPagesFound';
import {BASE_URL} from "../../constant"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #10ba07',
  boxShadow: 24,
  p: 4,
};

const ContactForm = styled.form`
  width: 60%;
  display: flex;  
  margin:auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
  border:4px solid #2cb015;  
  @media only screen and (max-width: 768px){
        width: 80%;
    }
   @media only screen and (max-width: 500px){
        width: 95%;
    }
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
export const NavBtn = styled.a`
  justify-content: center;
  margin:8px;
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(225deg, hsla(260, 100%, 50%, 1) 0%, hsla(550, 100%, 50%, 1) 100%);
  color: white;
  height: 70%;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.6s ease-in-out; 
  :hover {
      color: black;     
    }
  
`;
function BlogComponent({ post, fetchData, handleModelChange }) {
  const [isEdit, setIsEdit] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const { isAdmin, setIsAdmin, isLogin, setIsLogin } = useContext(AuthContex)
  const [form, setForm] = useState(post)

  const handleEdit = () => {
    setIsEdit(true)
    setIsSave(true)
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData=new FormData()
    if (form?.imgSrc) {
      formData.append('file', form.imgSrc);
    }
      formData.append('title',form?.title)
      formData.append('instructor',form?.instructor)
      formData.append('background',form?.background)
      formData.append('description',form?.description)
      formData.append('price',form?.price)  
    try {
      const { data } = await axios.put(`${BASE_URL}/course/update-blog/${post._id}`,formData, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return
      }
      setIsEdit(false);
      setIsSave(false);
      await fetchData();
    } catch (error) {
    }
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.delete(`${BASE_URL}/course/delete-blog/${post._id}`, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return
      }
      await fetchData();
    } catch (error) {
    }
  };
  const navigate = useNavigate();
  return (
    <div className={styles.blogPost} key={post?._id}>
       <Modal
      open={openModel}
      onClose={() => setOpenModel(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          You have to Login first!!
        </Typography>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => setOpenModel(false)}>OK</Button>
      </Box>
    </Modal>
      <div>
        {!isEdit ? (
          <img src={`${BASE_URL}/Images/${post.imgSrc}`} alt={post?.title} />
        ) : (
          <TextField
            id="standard-error-helper-text"
            placeholder="Image Src"
            variant="standard"
            type='file'
            onChange={(e) => setForm({...form,imgSrc:e.target.files[0]})}
          />
        )}
      </div>
      <h2 style={{ fontWeight: 800, fontSize: '14px' }}>
        {!isEdit ?
          <>{post.title}</> :
          <TextField
            id="standard-error-helper-text"
            variant="standard"
            value={form.title}
            style={{ width: "100%", marginTop: "8px" }}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />}
      </h2>
      <h5><span>Instructor: </span>
        {!isEdit ? <>{post.instructor}</> :
          <TextField
            id="standard-error-helper-text"
            variant="standard"
            value={form.instructor}
            style={{ width: "90%", marginTop: "8px" }}
            onChange={(e) => setForm({ ...form, instructor: e.target.value })}
          />}
      </h5>
      <p style={{ fontWeight: 800, fontSize: '12px' }}>
        {!isEdit ? <>{post.background}</> :
          <TextField
            id="standard-error-helper-text"
            variant="standard"
            value={form.background}
            style={{ width: "100%", marginTop: "8px" }}
            onChange={(e) => setForm({ ...form, background: e.target.value })}
          />
        }
      </p>
      <p style={{ fontWeight: 600, fontSize: '10px' }}>
        {!isEdit ? <><span style={{ fontWeight: 800, fontSize: '12px' }}>Price: </span>{post.price} rupees</> :
          <TextField
            id="standard-error-helper-text"
            variant="standard"
            value={form.price}
            style={{ width: "100%", marginTop: "8px" }}
            onChange={(e) => setForm({ ...form, price: e.target.value })}
          />}
      </p>
      <p style={{ fontWeight: 600, fontSize: '10px' }}>
        {!isEdit ? <>{post.description}</> :
          <TextField
            id="standard-error-helper-text"
            variant="standard"
            value={form.description}
            style={{ width: "100%", marginTop: "8px" }}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
          />}
      </p>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          isAdmin &&
          <>{!isEdit &&
            <span className={styles.btnLink} onClick={() => handleEdit()} style={{ width: "115px", height: '40px' }}>Edit</span>}
            {isSave && <span className={styles.btnLink} style={{ width: "115px", height: '40px' }} onClick={(e) => handleSubmit(e)}>Save</span>}</>
        }
        {
          !isAdmin && isLogin &&
          <a className={styles.btnLink} onClick={() => navigate(`/courseform/${post.title}/${post._id}/${post.price}`)} target="_blank" style={{ width: "115px", height: '40px' }}>Enroll Now</a>

        }
         {
          !isAdmin && !isLogin &&
          <span className={styles.btnLink}   style={{ width: "115px", height: '40px' }} onClick={()=>setOpenModel(true)}>Enroll Now</span>

        }
        {
          isAdmin &&
          <a className={styles.btnLink} onClick={(e) => handleDelete(e)} target="_blank" style={{ width: "115px", height: '40px', marginLeft: '8px' }}>Delete</a>
        } </div>
    </div>
  )
}

const Blog = () => {
  const { isAdmin, setIsAdmin, isLogin, setIsLogin } = useContext(AuthContex)
  const [openModelAddCourse, setOpenModelAddCourse] = useState(false)
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
  const [blogs, setBlogs] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('latest');
  const [filteredBlogs, setFilteredBlogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [openModel, setOpenModal] = useState(false)
  const [form, setForm] = useState({
    imgSrc: "",
    title: "",
    instructor: "",
    background: "",
    description: "",
    price: "",
  })
  const [isError, setIsError] = useState({
    isImgSrcError: false,
    isTitleError: false,
    isInstructorError: false,
    isBackgroundError: false,
    isDescriptionError: false,
    isPriceError: false,
  })
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isNotDataFilled: false
  })
  const handleChange = (e, value) => {
    if (value === "imgSrc") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isImgSrcError: true })
      } else {
        setIsError({ ...isError, isImgSrcError: false })
      }
      setForm({ ...form, imgSrc: e.target.value })
    } else if (value === "title") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isTitleError: true })
      } else {
        setIsError({ ...isError, isTitleError: false })
      }
      setForm({ ...form, title: e.target.value })
    } else if (value === "instructor") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isInstructorError: true })
      } else {
        setIsError({ ...isError, isInstructorError: false })
      }
      setForm({ ...form, instructor: e.target.value })
    } else if (value === "background") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isBackgroundError: true })
      } else {
        setIsError({ ...isError, isBackgroundError: false })
      }
      setForm({ ...form, background: e.target.value })
    } else if (value === "description") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isDescriptionError: true })
      } else {
        setIsError({ ...isError, isDescriptionError: false })
      }
      setForm({ ...form, description: e.target.value })
    } else if (value === "price") {
      if (e.target.value.length === 0 || e.target.value.length <= 1) {
        setIsError({ ...isError, isPriceError: true })
      } else {
        setIsError({ ...isError, isPriceError: false })
      }
      setForm({ ...form, price: e.target.value })
    }
  }
  const handleModelChange = () => setOpenModal(true)
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/course/get-all-blog`, {
        withCredentials: true
      });
      if (!(data?.success)) {
        return
      }
      setBlogs(data?.blogs)
      setFilteredBlogs(data?.blogs)
      setLoading(false)
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitFormError.loading) return;
    const fillAllData = Object.values(isError).some(value => value === true);
    const filled = Object.values(form).some(value => value.length === 0)
    if (fillAllData || filled) {
      setSubmitFormError({
        ...submitFormError, isNotDataFilled: true
      })
      return
    }

    try {
      setSubmitFormError({ ...submitFormError, loading: true, isNotDataFilled: false })
      const formData=new FormData()
      formData.append('file',form?.imgSrc)
      formData.append('title',form?.title)
      formData.append('instructor',form?.instructor)
      formData.append('background',form?.background)
      formData.append('description',form?.description)
      formData.append('price',form?.price)      
      const { data } = await axios.post(`${BASE_URL}/course/create-blog`, formData, {
        withCredentials: true
      });
      if (!(data?.success)) {
        setSubmitFormError({ ...submitFormError, loading: false, error: true })
        return
      }
      setOpenModelAddCourse(false)
      await fetchData()

    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  };
  const applyFilter = () => {
    let filteredUsers = blogs.filter((item) => {
      const matchesSearchQuery =
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      return matchesSearchQuery;
    });

    filteredUsers = filteredUsers.sort((a, b) => {
      if (sortOrder === 'latest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });

    setFilteredBlogs(filteredUsers);
  };
  useEffect(() => {
    setLoading(true)
    const time = setTimeout(() => {
      applyFilter();
      setLoading(false)
    }, 500);
    return () => clearTimeout(time);
  }, [searchQuery, sortOrder, blogs]);
  return (
    <div>      
     
      <Box className={styles.textLoop}>
        Ours
        <span className={styles.span}>
          <Typewriter
            options={{
              strings: "Courses..",
              autoStart: true,
              loop: true,
            }}
          />
        </span>
      </Box>
      <div style={{ display: 'flex', justifyContent: "center" }}>
        {
          isAdmin &&
          <span className={styles.btnLink} onClick={() => setOpenModelAddCourse(true)} style={{ width: "115px", height: '40px' }}>Add Course</span>
        }
      </div>
      <div className={styles.container}>
        <TextField
          id="outlined-basic"
          label="Search by course name"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FormControl fullWidth style={{ margin: 'auto', width: '30%' }}>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Sort
          </InputLabel>
          <NativeSelect
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value={"latest"} style={{ textAlign: 'center' }}>Latest</option>
            <option value={"earliest"} style={{ textAlign: 'center' }}>Earliest</option>
          </NativeSelect>
        </FormControl>
      </div>
      {
        loading ? <LoadingPages /> :        
          <div className={styles.container}>
            {filteredBlogs?.length>0?<>            
            {filteredBlogs.map((post, index) => (
              <BlogComponent post={post} fetchData={fetchData} handleModelChange={handleModelChange} />
            ))}</>:
            <NoPagesFound contain={"Don't have any course"}></NoPagesFound>
          }
          </div>}         

      <Modal
        open={openModelAddCourse}
        onClose={() => setOpenModelAddCourse(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactForm >
          <ContactTitle>Add Course</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Image Src"
            variant="standard"
            type='file'
            onChange={(e) => setForm({...form,imgSrc:e.target.files[0]})}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Title"
            variant="standard"
            value={form?.title}
            error={isError?.isTitleError}
            helperText={isError?.isTitleError && "Pls enter validate title"}
            onChange={(e) => handleChange(e, "title")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Instructor"
            variant="standard"
            value={form?.instructor}
            error={isError?.isInstructorError}
            helperText={isError?.isInstructorError && "Pls enter validate instructor"}
            onChange={(e) => handleChange(e, "instructor")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Background"
            variant="standard"
            value={form?.background}
            error={isError?.isBackgroundError}
            helperText={isError?.isBackgroundError && "Pls enter validate background"}
            onChange={(e) => handleChange(e, "background")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Description"
            variant="standard"
            value={form?.description}
            error={isError?.isDescriptionError}
            helperText={isError?.isDescriptionError && "Pls enter validate description"}
            onChange={(e) => handleChange(e, "description")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Price"
            variant="standard"
            value={form?.price}
            error={isError?.isPriceError}
            helperText={isError?.isPriceError && "Pls enter validate price"}
            onChange={(e) => handleChange(e, "price")}
          />
          <NavBtn variant="outlined" onClick={(e) => handleSubmit(e)}>Add</NavBtn>
          {
            submitFormError.success &&
            <Alert severity="success" color="success">
              Your Application has been submitted successfully
            </Alert>
          }
          {
            submitFormError.error &&
            <Alert severity="error" color="error">
              {errorMsg}
            </Alert>
          }
          {
            submitFormError.isNotDataFilled &&
            <Alert severity="error" color="error">
              Please fill proper data
            </Alert>
          }
        </ContactForm>
      </Modal>
      <Modal
        open={openModel}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            You need to login first!!!
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Blog;