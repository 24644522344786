import React from 'react'
import myVideo from './myVideo.mp4'
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle,SocialMediaIcons,SocialMediaIcon, ResumeButton } from './HeroStyle'
import Typewriter from 'typewriter-effect';

const HeroSection = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <video src={myVideo} autoPlay loop muted >
                    </video>
                </HeroBg>
                <HeroInnerContainer>
                    <HeroLeftContainer id="Left">
                        <Title>Learn Earn Grow With Aminurmus Lernings...</Title>
                        <TextLoop>
                        Transforming Passion into
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: " Profession...",
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <ResumeButton href="mailto:info@aminurmus.com" target='display'>Join Today</ResumeButton>
                    </HeroLeftContainer>
                </HeroInnerContainer>
            </HeroContainer>
        </div>
    )
}

export default HeroSection