import React, { useEffect, useState } from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserImage from './user.png'
import axios from 'axios';
import {BASE_URL} from "../../constant"
const Blog = () => {
    const [blogs,setBlogs]=useState([])
    const navigate = useNavigate();
    const fetchData=async()=>{
        try {
            const {data} = await axios.get(`${BASE_URL}/hackathon-form/get-all-blog`,{
                withCredentials:true
              });
              console.log(data?.blogs)
              if(!(data?.success)){
                return
              }
              setBlogs(data?.blogs)
        } catch (error) {
            console.log({error})
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <div>
        
      <div  className={styles.container}>
        {blogs.map((post, index) => (<>
          <div  className={styles.blogPost} key={post?._id}>
            <div style={{display:'flex',justifyContent:'center'}}>
            <img src={UserImage} alt={post.title} style={{height:'200px',width:'200px'}} />
            </div>
            <h5><span>Appled For: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.title}</span> </h5>
            <h5><span>name: </span> <span style={{fontWeight:600,fontSize:'12px'}}>{post.name}</span> </h5>
            <h5><span>email: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.email} </span></h5>
            <h5><span>phone No: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.phoneNumber}</span> </h5>
            <h5><span>collage: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.collage}</span> </h5>            
            <h5><span>year: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.year}</span> </h5>            
            <h5><span>collageDegree: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.collageDegree}</span> </h5>            
            <h5><span>teamName: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.teamName}</span> </h5>            
            <h5><span>teamSize: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.teamSize}</span> </h5>            
            <h5><span>teamMember: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.teamMember}</span> </h5>            
            <h5><span>primarySkill: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.primarySkill}</span> </h5>            
            <h5><span>secondarySkill: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.secondarySkill}</span> </h5>            
            <h5><span>titleOfProject: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.titleOfProject}</span> </h5>            
            <h5><span>briefDescription: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.briefDescription}</span> </h5>            
            <h5><span>anyComment: </span><span style={{fontWeight:600,fontSize:'12px'}}>{post.anyComment}</span> </h5>            
           
            <div style={{display:'flex',justifyContent:'center'}}>

            {/* <a  className={styles.btnLink} onClick={() => navigate(`/hackathonform/${post.title}`)} target="_blank" style={{width:"115px",height:'40px'}}>Register</a> */}
     </div>
          </div>
          </>))}
      </div>
    </div>
  );
};

export default Blog;