import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typewriter from 'typewriter-effect';
import styles from './myStyle.module.css'; 
import { useNavigate } from 'react-router-dom';

const ImageCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  border:"2px solid transparent",
  borderRadius:"20px",
  '&:hover .descriptionBox': {
    transform: 'translateY(0)',
  },
}));

const DescriptionBox = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',

  transform: 'translateY(100%)',
  transition: 'transform 0.3s ease-in-out',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

function ImageCardComponent ({ title, image, description }) {
  const navigate=useNavigate()
return (
<>
  <ImageCard>
    <CardMedia
      component="img"
      image={image}
      alt="Card Image"
      style={{ height: 300}}
    />
    <DescriptionBox className="descriptionBox">
    <Typography variant="h5" style={{fontWeight:900}}>{title}
    </Typography>
      <Typography variant="h6" style={{marginTop:"20px"}}>{description}</Typography>
      <div style={{textAlign:"-webkit-center",marginTop:"20px"}}>

      <span   onClick={()=>navigate('/courses')} className={styles.btnLink} target="_blank" style={{width:"115px",height:'40px'}}>Lets Start</span>
      </div>
    </DescriptionBox>
  </ImageCard>
  </>
)
}

export default function App() {  
  const cards = [
    { 
        image: 'https://emaillistvalidation.com/blog/content/images/2023/10/Mern.png', 
        title: 'Full Stack Web Development', 
        description: "Build modern web applications from scratch." 
    },
    { 
        image: 'https://th.bing.com/th/id/OIP.Qwta26SNFoftkL231g4APAHaEc?w=1000&h=600&rs=1&pid=ImgDetMain', 
        title: 'Java Full Stack Development', 
        description: "Master Java and popular frameworks for full-stack development." 
    },
    { 
        image: 'https://staticlearn.shine.com/l/m/images/blog/Android.jpg', 
        title: 'Mobile App Development (Android)', 
        description: "Create Android applications using Java and Kotlin." 
    },
    { 
        image: 'https://th.bing.com/th/id/OIP.sdhSluzG5ba8G0angIGs5AHaEK?rs=1&pid=ImgDetMain', 
        title: 'Data Science and Analytics', 
        description: "Analyze data using Python, R, and machine learning." 
    },
    { 
        image: 'https://1.bp.blogspot.com/-GhlZqYUD-_I/X3HJ2oS3WCI/AAAAAAAAAEA/zsjT20-Obh8IqXb_DUBgeYFXWdJwDgWiwCNcBGAsYHQ/w1200-h630-p-k-no-nu/Ai-ml-dl.jpg', 
        title: 'Artificial Intelligence & Machine Learning', 
        description: "Build AI models and integrate them into applications." 
    },
    { 
        image: 'https://i1.wp.com/securenetworkers.com/wp-content/uploads/2019/12/AdobeStock_199796751-scaled.jpeg?fit=2560%2C1491&ssl=1', 
        title: 'Cybersecurity', 
        description: "Learn to secure systems from cyber threats." 
    },
    { 
        image: 'https://icsblog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2023/04/29163136/4-Pillars-of-Cloud-Computing-1.jpg', 
        title: 'Cloud Computing (AWS/Azure/GCP)', 
        description: "Understand cloud services and architecture." 
    },
    { 
        image: 'https://img.freepik.com/free-vector/gradient-devops-illustration_23-2149370941.jpg', 
        title: 'DevOps', 
        description: "Automate software deployment and monitoring." 
    },
    { 
        image: 'https://www.portnox.com/wp-content/uploads/2023/06/Blockchain-and-IoT-1.png', 
        title: 'Blockchain Development', 
        description: "Explore blockchain technology and smart contracts." 
    },
    { 
        image: 'https://th.bing.com/th/id/OIP.sRJEaoyGEmlQ0H4ovwV-MgAAAA?rs=1&pid=ImgDetMain', 
        title: 'Software Testing & QA', 
        description: "Learn manual and automated testing strategies." 
    },
    { 
        image: 'https://wallpapercave.com/wp/wp9775513.jpg', 
        title: 'UI/UX Design', 
        description: "Create intuitive interfaces for web and mobile apps." 
    },
    { 
        image: 'https://images.datacamp.com/image/upload/v1646560927/shutterstock_1329434768_bf9003ea47.jpg', 
        title: 'Python Programming', 
        description: "Master Python for software development and data science." 
    },
    { 
        image: 'https://images.squarespace-cdn.com/content/v1/55181a36e4b05c72e7f6a2a3/1644642964684-IFR844O0H5ODZMLIHES2/2-1-1.png', 
        title: 'Internet of Things (IoT) Development', 
        description: "Build IoT applications using hardware and software." 
    },
    { 
        image: 'https://th.bing.com/th/id/OIP.yGvAAV7oGACnJcTv848mVQAAAA?rs=1&pid=ImgDetMain', 
        title: 'Cloud Native Application Development', 
        description: "Design and deploy applications in the cloud." 
    },
    { 
        image: 'https://wallpapercave.com/wp/wp9358224.jpg', 
        title: 'Database Management & SQL', 
        description: "Manage databases and write SQL queries." 
    },
    { 
        image: 'https://wallpapercave.com/wp/wp9358224.jpg', 
        title: 'Ethical Hacking', 
        description: "Learn penetration testing and network security." 
    },
    { 
        image: 'https://binmile.com/wp-content/uploads/2021/04/why-businesses-elect-angular-for-developing-digital-products.jpg', 
        title: 'Angular Development', 
        description: "Build single-page applications with Angular." 
    },
    { 
        image: 'https://e0.pxfuel.com/wallpapers/737/10/desktop-wallpaper-react-native.jpg', 
        title: 'React Native Development', 
        description: "Develop cross-platform mobile apps using React Native." 
    },
    { 
        image: 'https://c1.wallpaperflare.com/preview/24/329/110/seo-optimization-search-engine-optimization-process.jpg', 
        title: 'Digital Marketing with SEO', 
        description: "Learn SEO and digital marketing strategies." 
    },
    { 
        image: 'https://development.analyticsinsight.net/wp-content/uploads/2021/09/10-Ways-Big-Data-Has-Changed-Healthcare-in-India.jpg', 
        title: 'Big Data & Hadoop', 
        description: "Understand big data architecture and processing." 
    }
];

  return (
    <div id="services" style={{margin:'22px',marginTop:'60px'}}>
        <div className={styles.textLoop}>
            Ours
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Courses..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </div>
        <div className={styles.discr}>
        At Aminurmus Learning Platform, we are dedicated to empowering learners with the skills and knowledge needed to thrive in today's fast-paced technology landscape. Our diverse range of courses, led by industry experts from prestigious institutions like IITs, NITs, and top MNCs, offers hands-on experience and practical insights into various domains, including Full Stack Development, Data Science, Artificial Intelligence, Cybersecurity, and more. Each course is designed to cater to beginners and advanced learners alike, providing comprehensive content that covers the latest tools and technologies. By engaging in our interactive learning environment, students will not only gain technical expertise but also develop problem-solving skills and industry-relevant knowledge. Our platform fosters a collaborative community where learners can connect, share ideas, and gain valuable feedback, ultimately preparing them for successful careers in the tech industry. Join us at Aminurmus Learning Platform, where your journey to becoming a skilled professional begins!
        </div>
    <Grid container spacing={4}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ImageCardComponent image={card.image} description={card.description} title={card.title}/>
        </Grid>
      ))}
    </Grid>
    </div>
  );
}